import axios from "axios";
import { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";

export function Connection({ fos, setfos, name, img }) {
    const [form, setForm] = useState(false);

    function Clear() {
        setfos(!fos);
        setForm(false);
    }

    function def(e) {
        e.preventDefault();
        const element = {
            name: e.target[0].value,
            mail: e.target[1].value,
            tel: e.target[2].value,
            text: e.target[3].value,

            type: e.target[4].value,
        };

        console.log(e);
        console.log(element);
        axios({
            method: "POST",
            // url: 'https://specavto.agatech.ru/mail/mail.php',
            url: "/mail/mail.php",
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(element),
        })
            .then((response) => {
                console.log(response);
                setForm(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    return (
        <>
            {!fos && (
                <div onClick={() => Clear(!fos)} className="connection-button">
                    <img src={process.env.PUBLIC_URL + "/img/conn.png"} alt="connection" />
                </div>
            )}
            <div onClick={() => Clear(!fos)} className={fos ? "connection-fos active" : "connection-fos"}>
                <span onClick={() => Clear(!fos)} className="beacs">
                    Закрыть х
                </span>

                <div onClick={(e) => e.stopPropagation()} className="connection-fos_body">
                    <form onSubmit={def} className="connection-fos_bodyBox">
                        {name && <span className="formname">{name}</span>}
                        {!img ? (
                            <img src={process.env.PUBLIC_URL + "/logo_old.png"} alt="" />
                        ) : (
                            <img src={process.env.PUBLIC_URL + `/img/katalog/galery/${img}`} alt="" />
                        )}
                        {!form ? (
                            <>
                                <span className="ps">Здравствуйте ,оставьте свои контакты и мы перезвоним вам в ближайшее время</span>
                                <input required type="text" placeholder="Ваше имя" />
                                <input required type="text" placeholder="Ваш e - mail" />

                                <ReactInputMask required placeholder="Ваш телефон" mask="+7 (999) 999 99 99" type="text" />
                                <textarea required type="text" maxLength={254} rows={5} placeholder="Задайте Ваш вопрос" />

                                <p>
                                    Отправляя заявку, Вы соглашаетесь с{" "}
                                    <a href="/polzovatelskoe" type="_blank" className="orange">
                                        политикой конфиденциальности
                                    </a>
                                </p>
                                {!img ? (
                                    <input type="hidden" defaultValue="Обратная связь" />
                                ) : (
                                    <input type="hidden" defaultValue={"Узнать цену " + name} />
                                )}
                                <button className="news_button but">отправить</button>
                            </>
                        ) : (
                            <span className="ps">Ваше сообщение успешно отправлено, мы свяжемся с Вами в ближайшее время.</span>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
}
