import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import "./font/Disket-Mono/stylesheet.css";
import "./font/OpenSans/stylesheet.css";
import "./font/SemiBold/stylesheet.css";
import "./font/Roboto/stylesheet.css";
import "./css/glob.css";
import { Provider } from 'react-redux';

import { AppRouste } from './rpute';
import { store } from './stor/store';

import "./css/media.css";


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
    <AppRouste/>

    </Provider>
   
  </React.StrictMode>
);

