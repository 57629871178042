import { BreadCrumbs } from "../bloc/breadСrumbs";
import { LineKlient } from "../bloc/lineKlient";
import "../css/kompani.css";
import Ani from "../bloc/ani";

export function Kompani(params) {
    return (
        <>
            <main className="kompani">
                <BreadCrumbs />
                <section>
                    <div className="container">
                        <div className="kompani_title_and_text">
                            <h1 className="ani ani-1">О КОМПАНИИ</h1>
                            <p className="kompani_text ani ani-1">
                                Предприятие ООО "Спецавто" - ваш надежный
                                <br />и проверенный партнер в сфере производства спецавтомобилей и прицепов с 1993 года
                            </p>
                        </div>
                        <img className="kompani_main_picture baner_comp ani ani-2" src={process.env.PUBLIC_URL + "img/baner.png"} alt="банер" />
                        <img
                            className="kompani_main_picture baner_mobile ani ani-2"
                            src={process.env.PUBLIC_URL + "img/baner_mobile.jpg"}
                            alt="банер"
                        />
                        <div className="block_number_all_inform ani ani-2">
                            <p className="block_number_main_title">
                                Мы являемся официальными доработчиками завода УАЗ, что подтверждает нашу компетентность и профессионализм
                            </p>
                            <div className="block_number_all_cards">
                                <div className="block_number_card">
                                    <p className="block_number_card_num">146</p>
                                    <p className="block_number_card_text">городов России и Казахстана пользуются продукцией Спецавто</p>
                                </div>
                                <div className="block_number_card">
                                    <p className="block_number_card_num">30</p>
                                    <p className="block_number_card_text">лет опыта</p>
                                </div>
                                <div className="block_number_card">
                                    <p className="block_number_card_num">100k</p>
                                    <p className="block_number_card_text">довольных клиентов</p>
                                </div>
                                <div className="block_number_card">
                                    <p className="block_number_card_num">20</p>
                                    <p className="block_number_card_text">моделей автомобильного ряда</p>
                                </div>
                            </div>
                        </div>
                        <div className="kompani_info">
                            <img className="ani ani-3" src={process.env.PUBLIC_URL + "img/kompani3.jpg"} alt="фото" />
                            <div className="kompani_info_text ani ani-4">
                                <p>
                                    Мы гордимся тем, что за это время заключили более 5000 успешных договоров и стали одним из значимых предприятий в
                                    нашей отрасли.
                                </p>

                                <p>
                                    Одной только Россией география продаж автомобилей не ограничивается: помимо внутреннего рынка, машины уходят в
                                    города Ближнего и Дальнего зарубежья.
                                </p>

                                <p>Мы надеемся, что наш опыт и знания будут полезны Вам!</p>

                                {/* <p className="gen">
                                        <i>
                                            Генеральный директор <br />
                                            <b>Костяев Михаил Вячеславович</b>
                                        </i>
                                    </p> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="become_partner">
                    <p className="kompani_advantages_ps ani ani-2">
                        <p className="kompani_advantages_ps_main_text">
                            Мы гордимся доверием наших заказчиков и стремимся стать вашим надежным партнером на долгие годы.
                        </p>
                        <p className="kompani_advantages_ps_standart_text">
                            Обратившись в ООО «Спецавто», вы можете быть уверены, что получите лучшую автоспецтехнику, которая полностью соответствует
                            вашим требованиям и ожиданиям.
                        </p>
                        <button onClick={() => params.setfos(!params.fos)} className="news_button but">
                            стать партнером
                        </button>
                    </p>
                </section>
                <section className="kompani_advantages">
                    <div className="container">
                        {/* <div className="kompani_advantages_box ani ani-1">
                            <Advantages
                                title="Широкий ассортимент"
                                text="
                    За свою тридцатилетнюю историю компания ООО «Спецавто» готова предложить вам широкий спектр автоспецтехники, который полностью соответствуют самым высоким требованиям. Вся наша продукция проходит строгий контроль качества и безопасности, что позволяет вам быть полностью уверенными в их надежности и долговечности.
                    Мы рассмотрим любое предложение заказчика и беремся за изготовление самых нестандартных вариантов автоспецтехники.
                    "
                            />
                            <Advantages
                                title="Надежные партнеры"
                                text="Мы являемся надежным партнером, на которого вы можете положиться. Когда дело касается гарантийных обязательств, мы всегда берем это на себя. Если у вас возникнет проблема во время использования спецавтомобиля, наша команда специалистов гарантирует оперативное решение этой проблемы. Мы понимаем, насколько важно для вас сохранить бесперебойную работу вашей техники, поэтому прикладываем все усилия, чтобы устранить неисправности в кратчайшие сроки."
                            />
                            <Advantages
                                title="Система оплаты"
                                text="
                Кроме того, мы предлагаем гибкую систему оплаты и финансирования, чтобы сделать приобретение спецтехники максимально удобным и выгодным для вас. Наша команда экспертов всегда готова помочь вам выбрать оптимальное решение, учитывая ваши индивидуальные потребности и бюджет. Мы сделаем все возможное, чтобы вы остались довольны сотрудничеством с нами.
                "
                            />
                        </div> */}
                        <div className="kompani_advantages_all_inform">
                            <div className="kompani_advantages_text_inform ani ani-3">
                                <h2>ПРЕИМУЩЕСТВА</h2>
                                <details name="advantages_item">
                                    <summary className="kompani_advantages_visible_inf">
                                        <p>Широкий ассортимент</p>
                                        <img src="img/arrow_forward.svg" alt="стрелка" />
                                    </summary>
                                    <div className="kompani_advantages_invisible_inf">
                                        <p>
                                            За свою тридцатилетнюю историю компания ООО «Спецавто» готова предложить вам широкий спектр
                                            автоспецтехники, который полностью соответствуют самым высоким требованиям. Вся наша продукция проходит
                                            строгий контроль качества и безопасности, что позволяет вам быть полностью уверенными в их надежности и
                                            долговечности. <br />
                                            <br />
                                            Мы рассмотрим любое предложение заказчика и беремся за изготовление самых нестандартных вариантов
                                            автоспецтехники.
                                        </p>
                                    </div>
                                </details>
                                <details name="advantages_item">
                                    <summary className="kompani_advantages_visible_inf">
                                        <p>Надежные партнеры</p>
                                        <img src="img/arrow_forward.svg" alt="стрелка" />
                                    </summary>
                                    <div className="kompani_advantages_invisible_inf">
                                        <p>
                                            Мы являемся надежным партнером, на которого вы можете положиться. Когда дело касается гарантийных
                                            обязательств, мы всегда берем это на себя. Если у вас возникнет проблема во время использования
                                            спецавтомобиля, наша команда специалистов гарантирует оперативное решение этой проблемы. Мы понимаем,
                                            насколько важно для вас сохранить бесперебойную работу вашей техники, поэтому прикладываем все усилия,
                                            чтобы устранить неисправности в кратчайшие сроки.
                                        </p>
                                    </div>
                                </details>
                                <details name="advantages_item">
                                    <summary className="kompani_advantages_visible_inf">
                                        <p>Система оплаты</p>
                                        <img src="img/arrow_forward.svg" alt="стрелка" />
                                    </summary>
                                    <div className="kompani_advantages_invisible_inf">
                                        <p>
                                            Кроме того, мы предлагаем гибкую систему оплаты и финансирования, чтобы сделать приобретение спецтехники
                                            максимально удобным и выгодным для вас. Наша команда экспертов всегда готова помочь вам выбрать
                                            оптимальное решение, учитывая ваши индивидуальные потребности и бюджет. Мы сделаем все возможное, чтобы вы
                                            остались довольны сотрудничеством с нами.
                                        </p>
                                    </div>
                                </details>
                            </div>
                            <div className="kompani_advantages_picture ani ani-4">
                                <img src="img/picture.png" alt="картинка" />
                            </div>
                        </div>
                    </div>
                </section>
                <LineKlient />
            </main>
            <Ani />
        </>
    );
}

// function Advantages(params) {
//     return (
//         <div className="advantages">
//             <h3>{params.title}</h3>
//             <p>{params.text}</p>
//         </div>
//     );
// }
