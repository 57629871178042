import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Main } from "./page/main";
import { Footer } from "./bloc/footer";
import { Header } from "./bloc/header";
import { Connection } from "./bloc/connection";
import { Avto } from "./page/gallariAuto";
import { Error } from "./page/404";
import { Kontact } from "./page/kontact";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { todosSelector } from "./stor/selectors";
import { allPrev } from "./stor/thunk";
import { Kompani } from "./page/kompani";
import NewsPages from "./page/newsPag";
import NewsAll from "./page/newsAll";
import { Katalogs } from "./page/katalog";
import ScrollToTop from "./bloc/ScrolToTop";
import Polzovatelskoe from "./page/polzovatelskoe";

export function AppRouste(params) {
    const [fos, setfos] = useState(false);
    const [fomName, setfomName] = useState(null);
    const [formImg, setformImg] = useState(null);

    const dispatch = useDispatch();
    const data = useSelector(todosSelector);

    useEffect(() => {
        if (!data.all) {
            dispatch(allPrev({ kat: 1 }));
        }
    }, []);
    useEffect(() => {}, [fos]);

    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <>
                    {" "}
                    <ScrollToTop>
                        <Header fos={fos} setfos={setfos} />
                        <Main fos={fos} setfos={setfos} />
                        <Footer fos={fos} setfos={setfos} />
                        <Connection fos={fos} setfos={setfos} />{" "}
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/katalog",
            element: (
                <>
                    {" "}
                    <ScrollToTop>
                        <Header fos={fos} setfos={setfos} />
                        <Katalogs />
                        <Footer fos={fos} setfos={setfos} />
                        <Connection fos={fos} setfos={setfos} />{" "}
                    </ScrollToTop>
                </>
            ),
        },
        {
            pure: false,
            path: "/katalog/uaz/:name",
            element: (
                <>
                    {" "}
                    <ScrollToTop>
                        <Header fos={fos} setfos={setfos} />
                        <Avto setfos={setfos} setfomName={setfomName} setformImg={setformImg} />
                        <Footer fos={fos} setfos={setfos} />
                        <Connection name={fomName} img={formImg} fos={fos} setfos={setfos} />{" "}
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "*",
            element: (
                <>
                    {" "}
                    <ScrollToTop>
                        <Header fos={fos} setfos={setfos} />
                        <Error />
                        <Footer fos={fos} setfos={setfos} />
                        <Connection fos={fos} setfos={setfos} />{" "}
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/kontact",
            element: (
                <>
                    {" "}
                    <ScrollToTop>
                        <Header fos={fos} setfos={setfos} />
                        <Kontact />
                        <Footer fos={fos} setfos={setfos} />
                        <Connection fos={fos} setfos={setfos} />{" "}
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/kompani",
            element: (
                <>
                    {" "}
                    <ScrollToTop>
                        <Header fos={fos} setfos={setfos} />
                        <Kompani fos={fos} setfos={setfos} />
                        <Footer fos={fos} setfos={setfos} />
                        <Connection fos={fos} setfos={setfos} />{" "}
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/news/:id",
            element: (
                <>
                    {" "}
                    <ScrollToTop>
                        <Header fos={fos} setfos={setfos} />
                        <NewsPages />
                        <Footer fos={fos} setfos={setfos} />
                        <Connection fos={fos} setfos={setfos} />{" "}
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/news",
            element: (
                <>
                    {" "}
                    <ScrollToTop>
                        <Header fos={fos} setfos={setfos} />
                        <NewsAll />
                        <Footer fos={fos} setfos={setfos} />
                        <Connection fos={fos} setfos={setfos} />{" "}
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/katalog",
            element: (
                <>
                    {" "}
                    <ScrollToTop>
                        <Header fos={fos} setfos={setfos} />
                        <Katalogs />
                        <Footer fos={fos} setfos={setfos} />
                        <Connection fos={fos} setfos={setfos} />{" "}
                    </ScrollToTop>
                </>
            ),
        },
        {
            path: "/polzovatelskoe",
            element: (
                <>
                    {" "}
                    <ScrollToTop>
                        <Header fos={fos} setfos={setfos} />
                        <Polzovatelskoe />
                        <Footer fos={fos} setfos={setfos} />
                        <Connection fos={fos} setfos={setfos} />{" "}
                    </ScrollToTop>
                </>
            ),
        },
    ]);

    return <RouterProvider router={router} />;
}
